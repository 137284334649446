<template>
  <div>
    <div class="row mb-5">
      <div class="col-12">
        <span class="display-4 col-12">
          {{ `${supplier.company_name}` }} - {{ $t("COMMON.IMPORT") }}
        </span>
      </div>
      <div
        class="col-12 mt-3"
        v-if="
          $currentUserCan($permissions.PERM_CREATE_PRODUCTS) &&
          $currentUserCan($permissions.PERM_EDIT_PRODUCTS) &&
          $currentUserCan($permissions.PERM_DELETE_PRODUCTS)
        "
      >
        <base-button
          @click="goToImportPage()"
          type="button"
          class="btn btn-sm btn-primary text-uppercase px-3"
        >
          {{ $t("IMPORTS.ADD_SYNCHRONIZATION") }}
        </base-button>
      </div>
    </div>
    <template v-if="supplier.id">
      <import-list-table
        filterLinkedObjectType="App\Models\Supplier"
        :filterLinkedObjectId="supplier.id"
      />
    </template>
  </div>
</template>
<script>
import ImportListTable from "@/views/Pages/AdminModule/ImportModule/Partials/ImportListTable";

export default {
  name: "supplier-view-products-import",

  components: {
    ImportListTable,
  },

  props: ["supplier"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {
    goToImportPage() {
      this.$router.push({
        name: "Add Imports",
        query: {
          id: this.supplier.id,
          linkedObjectType: "suppliers",
          model: "Product",
          organization: this.supplier?.organization?.id,
        },
      });
    },
  },

  mounted() {},

  watch: {},
};
</script>
