<template>
  <div class="">
    <div class="mb-3">
      <h1>{{ `${supplier.company_name}` }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="supplier.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="supplier.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.FISCAL_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ supplier.fiscal_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ supplier.email }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd class="col-sm-8">
            {{
              supplier.phone_type
                ? $t(`COMMON.PHONE_TYPE_${supplier.phone_type}`)
                : null
            }}:
            {{ supplier.phone }}
            {{
              supplier.phone_extension
                ? ` (ext: ${supplier.phone_extension})`
                : null
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd class="col-sm-8">
            <span v-for="(line, index) in supplier.other_phones" :key="index">
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8">
            {{ supplier.country }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8">
            {{ supplier.state }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            {{ supplier.city }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            {{ supplier.zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ supplier.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.TAGS") }}
          </dt>
          <dd class="col-sm-8">
            <tags :tags="supplier.tags" @tagsUpdated="supplierUpdated" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8">
            <div v-html="supplier.excerpt"></div>
          </dd>
        </dl>

        <div class="col-12 text-center mb-2 mt-5">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>

        <billing-informations-view
          :billingInformations="supplier"
          :type="supplier.billing_entity_type"
        />

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(supplier.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(supplier.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Tags from "@/components/Tags.vue";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "supplier-view-global",

  components: { Tags, BillingInformationsView },

  props: ["supplier"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    supplierUpdated() {
      this.$emit("supplierUpdated", true);
    },
  },

  watch: {
    supplier(supplier) {},
  },
};
</script>
