<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${supplier.company_name}` }} - {{ $t("COMMON.PRODUCTS") }}
      </span>
    </div>

    <supplier-product-list
      v-if="supplier.id"
      :filterSupplier="supplier.id"
      :filterOrganization="supplier.organization.id"
      :supplier="supplier"
      :addLineText="$t('SUPPLIERS.ADD_PRODUCT')"
    />
  </div>
</template>
<script>
import SupplierProductList from "@/components/SupplierProduct/SupplierProductList.vue";

export default {
  name: "supplier-view-supplier-products",

  components: { SupplierProductList },

  props: ["supplier"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
